'use client';

import {createContext, useState} from 'react';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';

import type {Dispatch, PropsWithChildren} from 'react';

export const JobBoardFilterContext = createContext<{
	filter: JobBoardFilterConfig;
	setFilter: Dispatch<React.SetStateAction<JobBoardFilterConfig>>;
	loading: boolean;
	setLoading: Dispatch<React.SetStateAction<boolean>>;
}>({
	filter: {},
	setFilter: () => {},
	loading: false,
	setLoading: () => {},
});

export type JobBoardFilterProviderProps = PropsWithChildren<{
	initialFilterValue: JobBoardFilterConfig;
}>;

export const JobBoardFilterProvider = ({initialFilterValue, children}: JobBoardFilterProviderProps) => {
	const [filter, setFilter] = useState(initialFilterValue);
	const [loading, setLoading] = useState(false);

	return (
		<JobBoardFilterContext.Provider value={{filter, setFilter, loading, setLoading}}>
			{children}
		</JobBoardFilterContext.Provider>
	);
};

import {createContext, useId} from 'react';
import {twMerge} from 'tailwind-merge';

import type {PropsWithChildren, ReactNode} from 'react';
import Label from '../label';
import type {LabelProps} from '../label';

export type FormControlProps = PropsWithChildren<
	{
		label?: ReactNode;
		required?: boolean;
		invalid?: boolean;
		description?: ReactNode;
		errorMessage?: ReactNode;
		helperMessage?: ReactNode;
		labelPosition?: LabelProps['position'];
		inlineLabel?: LabelProps['inline'];
	} & Omit<LabelProps, 'inline' | 'position' | 'htmlFor' | 'placeholder'>
>;

export const FormControlContext = createContext<Omit<FormControlProps, 'label'> & {label?: string}>({});

const FormControl = ({
	id,
	inlineLabel,
	labelPosition,
	description,
	label,
	invalid,
	errorMessage,
	helperMessage,
	required,
	className,
	children,
	...props
}: FormControlProps) => {
	const defaultId = useId();

	return (
		<FormControlContext.Provider
			value={{
				...props,
				invalid,
				label: typeof label === 'string' ? label : id || defaultId,
				id: id || defaultId,
			}}
		>
			<fieldset className={twMerge('min-w-0', className)}>
				<Label {...props} id={id || defaultId} inline={inlineLabel} position={labelPosition}>
					{label && (
						<div className={twMerge(required ? "after:text-red11 after:content-['*']" : '', 'mb-0.5')}>
							{label}
						</div>
					)}
					{children}
					{description && !invalid && (
						<div className="mt-0.5 select-none text-xs text-mauve11">{description}</div>
					)}
					{invalid && (
						<div className="mt-0.5 select-none text-xs text-red11" role="alert">
							{errorMessage}
						</div>
					)}
					{!invalid && helperMessage && (
						<div className="mt-0.5 select-none text-xs text-mauve11" role="note">
							{helperMessage}
						</div>
					)}
				</Label>
			</fieldset>
		</FormControlContext.Provider>
	);
};

FormControl.displayName = 'FormControl';

export default FormControl;

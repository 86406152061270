import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const dropdownMenuItemStyles = cva(
	[
		'flex items-center justify-between rounded-sm py-0.5 px-1.5 outline-none gap-3 my-0.5 text-mauve12',
		'data-[disabled=true]:opacity-40 data-[disabled=true]:pointer-events-none cursor-pointer select-none',
	],
	{
		variants: {
			destructive: {
				true: 'data-[highlighted]:bg-red9 data-[highlighted]:text-mauve1 dark:data-[highlighted]:text-mauve12 aria-selected:bg-red9 aria-selected:text-mauve1 dark:aria-selected:text-mauve12',
				false: 'data-[highlighted]:bg-mauve5  aria-selected:bg-mauve5',
			},
		},
		defaultVariants: {destructive: false},
	},
);

export type DropdownMenuItemVariantProps = VariantProps<typeof dropdownMenuItemStyles>;

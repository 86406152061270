import {forwardRef} from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {PropsWithChildren} from 'react';
import {avatarStyles} from './styles';
import type {AvatarVariantProps} from './styles';

export type AvatarImageProps = AvatarPrimitive.AvatarImageProps;
const AvatarImage = forwardRef<HTMLImageElement, AvatarImageProps>(({className, ...props}, forwardedRef) => (
	<AvatarPrimitive.Image {...props} className={twMerge('rounded-full', className)} ref={forwardedRef} />
));

export type AvatarFallbackProps = AvatarPrimitive.AvatarFallbackProps;
const AvatarFallback = forwardRef<HTMLSpanElement, AvatarFallbackProps>(
	({className, ...props}, forwardedRef) => (
		<AvatarPrimitive.Fallback
			{...props}
			className={twMerge(
				'flex h-full w-full items-center justify-center rounded-full font-mono font-bold',
				className,
			)}
			ref={forwardedRef}
		/>
	),
);

export type AvatarProps = PropsWithChildren<
	AvatarPrimitive.AvatarProps &
		AvatarVariantProps & {
			initials?: string;
			fallback?: boolean;
		}
>;
const Avatar = assign(
	forwardRef<HTMLSpanElement, AvatarProps>(({size, color, className, ...props}, forwardedRef) => (
		<AvatarPrimitive.Root
			{...props}
			className={twMerge(avatarStyles({size, color}), className)}
			ref={forwardedRef}
		/>
	)),
	{Image: AvatarImage, Fallback: AvatarFallback},
);

Avatar.displayName = 'Avatar';

export default Avatar;

export * from './styles';

export const JOB_STATUS = {
	ApplicationStarted: 'application-started',
	ApplicationSubmitted: 'application-submitted',
	InterviewScheduled: 'interview-scheduled',
	InterviewingCompleted: 'interviewing-completed',
	OfferReceived: 'offer-received',
	Closed: 'closed',
} as const;

export const JOB_EXIT = {
	ApplicationWithdrawn: 'application-withdrawn',
	ApplicationRejected: 'application-rejected',
	InterviewingWithdrawn: 'interviewing-withdrawn',
	InterviewingRejected: 'interviewing-rejected',
	OfferAccepted: 'offer-accepted',
	OfferRejected: 'offer-rejected',
	OfferRescinded: 'offer-rescinded',
	OfferExpired: 'offer-expired',
} as const;

export const JOB_STATUS_GROUP = {
	Application: 'application',
	Interview: 'interview',
	Offer: 'offer',
	Closed: 'closed',
} as const;

export const JOB_TYPE = {
	FullTime: 'full-time',
	PartTime: 'part-time',
	Contract: 'contract',
	Internship: 'internship',
	Apprenticeship: 'apprenticeship',
	Hourly: 'hourly',
	Other: 'other',
} as const;

export const JOB_TYPE_GROUP = {Regular: 'regular', Temporary: 'temporary', Other: 'other'} as const;

export const APPLICATION_RESPONSE = {Positive: 'positive', Negative: 'negative', Pending: 'pending'} as const;

export const jobStatusGroups = {
	[JOB_STATUS_GROUP.Application]: {label: 'Application', color: 'primary'},
	[JOB_STATUS_GROUP.Interview]: {label: 'Interview', color: 'secondary'},
	[JOB_STATUS_GROUP.Offer]: {label: 'Offer', color: 'success'},
	[JOB_STATUS_GROUP.Closed]: {label: 'Closed', color: 'info'},
};

export const jobStatuses = {
	[JOB_STATUS.ApplicationStarted]: {
		label: 'Bookmarked',
		group: JOB_STATUS_GROUP.Application,
		order: 0,
	},
	[JOB_STATUS.ApplicationSubmitted]: {
		label: 'Application Submitted',
		group: JOB_STATUS_GROUP.Application,
		order: 1,
	},
	[JOB_STATUS.InterviewScheduled]: {
		label: 'Interview Scheduled',
		group: JOB_STATUS_GROUP.Interview,
		order: 2,
	},
	[JOB_STATUS.InterviewingCompleted]: {
		label: 'Interviewing Completed',
		group: JOB_STATUS_GROUP.Interview,
		order: 3,
	},
	[JOB_STATUS.OfferReceived]: {label: 'Offer Received', group: JOB_STATUS_GROUP.Offer, order: 4},
	[JOB_STATUS.Closed]: {label: 'Opportunity Closed', group: JOB_STATUS_GROUP.Closed, order: 5},
};

export const jobExits = {
	[JOB_EXIT.ApplicationWithdrawn]: {
		label: 'Application Withdrawn',
		group: JOB_STATUS_GROUP.Application,
		color: 'warning',
	},
	[JOB_EXIT.ApplicationRejected]: {
		label: 'Application Rejected',
		group: JOB_STATUS_GROUP.Application,
		color: 'danger',
	},
	[JOB_EXIT.InterviewingWithdrawn]: {
		label: 'Interviewing Withdrawn',
		group: JOB_STATUS_GROUP.Interview,
		color: 'warning',
	},
	[JOB_EXIT.InterviewingRejected]: {label: 'Rejected', group: JOB_STATUS_GROUP.Interview, color: 'danger'},
	[JOB_EXIT.OfferAccepted]: {label: 'Offer Accepted', group: JOB_STATUS_GROUP.Offer, color: 'success'},
	[JOB_EXIT.OfferRejected]: {label: 'Offer Rejected', group: JOB_STATUS_GROUP.Offer, color: 'danger'},
	[JOB_EXIT.OfferRescinded]: {label: 'Offer Rescinded', group: JOB_STATUS_GROUP.Offer, color: 'warning'},
	[JOB_EXIT.OfferExpired]: {label: 'Offer Expired', group: JOB_STATUS_GROUP.Offer, color: 'warning'},
};

export const jobTypes = [
	{value: JOB_TYPE.FullTime, label: 'Full Time', group: JOB_TYPE_GROUP.Regular},
	{value: JOB_TYPE.PartTime, label: 'Part Time', group: JOB_TYPE_GROUP.Regular},
	{value: JOB_TYPE.Contract, label: 'Contract', group: JOB_TYPE_GROUP.Temporary},
	{value: JOB_TYPE.Internship, label: 'Internship', group: JOB_TYPE_GROUP.Temporary},
	{value: JOB_TYPE.Apprenticeship, label: 'Apprenticeship', group: JOB_TYPE_GROUP.Temporary},
	{value: JOB_TYPE.Other, label: 'Other', group: JOB_TYPE_GROUP.Other},
];

export const jobTypeGroups = [
	{value: JOB_TYPE_GROUP.Regular, label: 'Regular'},
	{value: JOB_TYPE_GROUP.Temporary, label: 'Temporary'},
	{value: JOB_TYPE_GROUP.Other, label: 'Other'},
];

export const jobRemoteKeys = {true: 'Remote', false: 'Onsite', hybrid: 'Hybrid'};
export type JobRemoteKey = keyof typeof jobRemoteKeys;

export type JobStatus = (typeof JOB_STATUS)[keyof typeof JOB_STATUS];
export type JobStatusGroup = (typeof JOB_STATUS_GROUP)[keyof typeof JOB_STATUS_GROUP];
export type JobType = (typeof JOB_TYPE)[keyof typeof JOB_TYPE];
export type JobTypeGroup = (typeof JOB_TYPE_GROUP)[keyof typeof JOB_TYPE_GROUP];
export type JobExit = (typeof JOB_EXIT)[keyof typeof JOB_EXIT];

import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import {labelStyles} from './styles';
import type {LabelVariantProps} from './styles';

export type LabelProps = ComponentPropsWithoutRef<'label'> & LabelVariantProps;
const Label = forwardRef<HTMLLabelElement, LabelProps>(
	({inline, position, readonly, id, disabled, className, ...props}, forwardedRef) => (
		<label
			{...props}
			aria-describedby={`${id}-field`}
			className={twMerge(labelStyles({inline, position, readonly, disabled}), className)}
			ref={forwardedRef}
		/>
	),
);

Label.displayName = 'Label';

export default Label;

export * from './styles';

import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const overlayStyles = cva(
	'absolute inset-0 z-20 flex items-center justify-center w-full h-full opacity-70',
	{
		variants: {
			color: {
				neutral: 'bg-mauve1 text-mauve11',
				primary: 'bg-violet1 text-violet11',
				secondary: 'bg-plum1 text-plum11',
				success: 'bg-green1 text-green11',
				warning: 'bg-yellow1 text-yellow11',
				danger: 'bg-red1 text-red11',
				info: 'bg-blue1 text-blue11',
			},
		},
		defaultVariants: {color: 'neutral'},
	},
);

export const overlaySpinnerStyles = cva('absolute left-1/2 -translate-x-1/2 transform', {
	variants: {
		position: {
			top: 'top-0 translate-y-full',
			center: 'top-1/2 -translate-y-1/2',
			bottom: 'bottom-0 -translate-y-full',
		},
	},
	defaultVariants: {position: 'center'},
});

export type OverlayVariantProps = VariantProps<typeof overlayStyles>;
export type OverlaySpinnerVariantProps = VariantProps<typeof overlaySpinnerStyles>;

import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const textFieldStyles = cva(
	'items-center gap-2 text-mauve11 rounded-md focus-visible:outline h-full text-sm',
	{
		variants: {
			size: {sm: 'py-1 px-2 text-base', md: 'py-1.5 px-2.5 text-base', lg: 'py-2 px-3 text-lg'},
			disabled: {true: 'opacity-50 [&&]:hover:border-mauve7'},
			readonly: {true: '[&&]:hover:border-mauve7'},
			subtle: {true: 'bg-transparent', false: 'bg-mauve3 border-2'},
			textarea: {false: ''},
			invalid: {
				true: 'border-red7 hover:border-red8 focus-visible:outline-red8 bg-red3 hover:bg-red4 active:bg-red5',
				false: 'hover:border-mauve8 focus-visible:outline-mauve8',
			},
			hidden: {true: 'hidden', false: 'flex'},
		},
		defaultVariants: {
			size: 'md',
			disabled: false,
			readonly: false,
			subtle: false,
			textarea: false,
			invalid: false,
			hidden: false,
		},
		compoundVariants: [
			{readonly: false, disabled: false, invalid: false, className: 'hover:bg-mauve4 active:bg-mauve5'},
			{textarea: false, size: 'sm', className: 'min-h-[2rem] h-fit'},
			{textarea: false, size: 'md', className: 'min-h-[2.5rem] h-fit'},
			{textarea: false, size: 'lg', className: 'min-h-[3rem] h-fit'},
			{subtle: false, invalid: false, className: 'border-mauve7'},
		],
	},
);

export type TextFieldVariantProps = VariantProps<typeof textFieldStyles>;

export const textFieldInputStyles = cva(
	[
		'flex-grow bg-transparent text-sm text-mauve12 min-w-0 truncate',
		'placeholder:font-extralight placeholder:text-mauve11',
		'outline-none focus-visible:outline-none text-left',
	],
	{
		variants: {
			disabled: {true: 'opacity-50 pointer-events-none'},
			readonly: {true: 'pointer-events-none'},
		},
		defaultVariants: {
			disabled: false,
			readonly: false,
		},
	},
);

export type TextFieldInputVariantProps = VariantProps<typeof textFieldInputStyles>;

import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import LoadingSpinner from '../loading-spinner';
import {overlaySpinnerStyles, overlayStyles} from './styles';
import type {LoadingSpinnerProps} from '../loading-spinner';
import type {OverlaySpinnerVariantProps, OverlayVariantProps} from './styles';

export type OverlayProps = ComponentPropsWithoutRef<'div'> &
	OverlayVariantProps & {
		loading?: boolean;
		loadingSpinnerSize?: LoadingSpinnerProps['size'];
		loadingSpinnerPosition?: OverlaySpinnerVariantProps['position'];
	};

const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
	(
		{loading, loadingSpinnerSize, loadingSpinnerPosition, color, children, className, ...props},
		forwardedRef,
	) => (
		<div {...props} className={twMerge(overlayStyles({color}), className)} ref={forwardedRef}>
			{children}
			{loading && (
				<LoadingSpinner
					className={overlaySpinnerStyles({position: loadingSpinnerPosition})}
					size={loadingSpinnerSize || 'lg'}
				/>
			)}
		</div>
	),
);

Overlay.displayName = 'Overlay';

export default Overlay;

export * from './styles';

import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const indicatorStyles = cva(
	'rounded-full outline-none flex items-center justify-center pointer-events-none border text-mauve12',
	{
		variants: {
			size: {
				xs: 'h-2 w-2 text-[0]',
				sm: 'h-3 w-3 text-[0]',
				md: 'h-4 w-4 text-xs',
				lg: 'h-5 w-5 text-xs',
				xl: 'h-6 w-6 text-sm',
			},
			color: {
				neutral: 'bg-mauve9 border-mauve7',
				primary: 'bg-violet9 border-violet7',
				secondary: 'bg-plum9 border-plum7',
				success: 'bg-green9 border-green7',
				warning: 'bg-yellow9 border-yellow7 text-mauve12 dark:text-mauve1',
				danger: 'bg-red9 border-red7',
				info: 'bg-blue9 border-blue7',
			},
			pulse: {true: 'animate-pulse'},
		},
		defaultVariants: {size: 'lg', color: 'neutral', pulse: false},
	},
);

export type IndicatorVariantProps = VariantProps<typeof indicatorStyles>;

import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {DynamicComponentProps} from '../../types';

export type SkeletonProps = DynamicComponentProps;
const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
	({className, as: Component = 'div', ...props}, ref) => (
		<Component className={twMerge('animate-pulse rounded-md bg-mauve7', className)} ref={ref} {...props} />
	),
);

export default Skeleton;

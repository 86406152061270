import {cva} from 'class-variance-authority';

import type {VariantProps} from 'class-variance-authority';

export const labelStyles = cva('text-sm font-medium text-mauve11 items-center gap-1.5 select-none', {
	variants: {
		position: {left: 'flex-row', right: 'flex-row-reverse'},
		inline: {true: 'flex', false: 'block'},
		disabled: {true: 'opacity-40 pointer-events-none'},
		readonly: {true: 'opacity-70 pointer-events-none'},
	},
	defaultVariants: {position: 'right', inline: false},
});

export type LabelVariantProps = VariantProps<typeof labelStyles>;

import {useCallback, useContext, useMemo, useTransition} from 'react';
import {useCookie} from 'react-use';
import {JOB_BOARD_FILTER_COOKIE} from '~/shared/constants/cookies';
import {JobBoardFilterContext} from '~/shared/providers/job-board-filter-provider';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';
import {isFilterValueDefined} from '~/shared/utils/job-board';
import omit from 'lodash.omit';
import {useRouter} from 'next/navigation';

export const useJobBoardFilter = () => {
	const router = useRouter();
	const {filter, setFilter} = useContext(JobBoardFilterContext);
	const [, setFilterCookie] = useCookie(JOB_BOARD_FILTER_COOKIE);
	const [, startTransition] = useTransition();

	const updateFilter = useCallback(
		(newFilter: JobBoardFilterConfig, onComplete?: () => void) => {
			setFilter(newFilter);
			setFilterCookie(JSON.stringify(newFilter), {sameSite: 'lax'});
			startTransition(() => {
				router.refresh();
				onComplete?.();
			});
		},
		[router, setFilter, setFilterCookie],
	);

	const updatePagination = useCallback(
		({pageIndex, pageSize}: {pageIndex: number; pageSize: number}) => {
			setFilter(prevFilter => ({...prevFilter, page: pageIndex, pageSize}));
		},
		[setFilter],
	);

	const clearFilter = useCallback(
		(onComplete?: () => void) => {
			setFilter(prev => ({page: 1, pageSize: prev.pageSize}));
			setFilterCookie(JSON.stringify({}), {sameSite: 'lax'});
			startTransition(() => {
				router.refresh();
				onComplete?.();
			});
		},
		[router, setFilter, setFilterCookie],
	);

	const getSearchParams = useCallback(
		(config: JobBoardFilterConfig) =>
			Object.entries(config)
				.filter(([key, value]) => isFilterValueDefined(key, value))
				.reduce((searchParams, [key, value]) => {
					searchParams.set(key, Array.isArray(value) ? JSON.stringify(value) : value.toString());
					return searchParams;
				}, new URLSearchParams()),
		[],
	);

	const filterSearchParams = useMemo(() => getSearchParams(filter), [filter, getSearchParams]);
	const shareableSearchParams = useMemo(
		() => getSearchParams(omit(filter, ['page', 'pageSize'])),
		[filter, getSearchParams],
	);

	return {
		filter,
		filterSearchParams,
		shareableSearchParams,
		updatePagination,
		updateFilter,
		clearFilter,
	};
};

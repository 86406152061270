import {forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import {indicatorStyles} from './styles';
import type {IndicatorVariantProps} from './styles';

export type IndicatorProps = IndicatorVariantProps & ComponentPropsWithoutRef<'div'>;

const Indicator = forwardRef<HTMLDivElement, IndicatorProps>(
	({pulse, size, color, className, ...props}, forwardedRef) => (
		<div
			{...props}
			className={twMerge(indicatorStyles({size, color, pulse}), className)}
			ref={forwardedRef}
		/>
	),
);

Indicator.displayName = 'Indicator';

export default Indicator;

export * from './styles';
